<template>
  <section
    class="content d-flex flex-column justify-content-center align-items-center maintenance-page"
  >
    <b-container fluid="sm">
      <img :src="threeDoctorsSVG" class="mb-5 three-doctors-svg" />
      <h1 class="maintenance__title">Site is under maintainance</h1>
      <p>
        Thanks for choosing Rapidr! We are currently conducting server
        maintenance and we will be back asap!
      </p>
      <p>
        If you wish to signup for consults, please leave your contact info
        <span class="hyperlink" @click="maintenanceContactForm">HERE</span>
        and we will reach out to you as priority.
      </p>
      <p>
        For non-consultation related purposes, please reach out to the Rapidr
        team at hello@rapidr.sg.
      </p>
      <p>Apologies for the inconvenience caused.</p></b-container
    >
  </section>
</template>

<script>
export default {
  data() {
    return {
      threeDoctorsSVG: require("@/assets/three-doctors.svg"),
    };
  },
  methods: {
    maintenanceContactForm() {
      window.open("https://forms.gle/BCxN8YRs3VCwk5je6", "_blank");
    },
  },
};
</script>

<style>
.maintainance-page {
  height: 100vh;
  font-size: 2rem;
  font-weight: 600;
}
.maintenance__title {
  margin-bottom: 100px;
}
.three-doctors-svg {
  max-width: 500px;
}
.hyperlink {
  color: blue;
  text-decoration: underline;
}
</style>
